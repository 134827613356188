import React, { useState, useEffect } from 'react';
import './AnimierterText.css';

const AnimierterText = ({ text, onAnimationComplete }) => {
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
        let timer;
        if (displayedText.length < text.length) {
            timer = setTimeout(() => {
                const nextChar = text[displayedText.length];
                setDisplayedText(prevText => prevText + nextChar);
            }, 100); // Ändere die Geschwindigkeit der Animation hier nach Bedarf
        } else {
            onAnimationComplete(); // Aufruf der Callback-Funktion, wenn die Animation abgeschlossen ist
        }
        return () => clearTimeout(timer);
    }, [displayedText, text, onAnimationComplete]);

    return (
        <span className="animierter-text">{displayedText}</span>
    );
};

export default AnimierterText;


