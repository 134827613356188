
import './App.css';
import Hauptseite from './Hauptseite';
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="blur-overlay"></div>
        <div className="content">
          <Hauptseite/>
        </div>
      </header>
    </div>
  );
}
export default App;
