import React, { useEffect, useState } from 'react';
import appleSVG from './SVGs/applerex-icon.svg';
import deezerSVG from './SVGs/deezer-rex-icon.svg';
import instaSVG from './SVGs/insta-rex-icon.svg';
import spotifySVG from './SVGs/spotify-rex-icon.svg';
import twitchSVG from './SVGs/twitch-rex-icon.svg';
import youtubeSVG from './SVGs/youtube_icon_trex.svg';
import tiktokSVG from './SVGs/TikTokRex.svg';
import './Svg_Container.css'; // Style importieren
import './Svg_Container.css'; // Style importieren

const Svg_Container = ({ signalReceived }) => {
  const [animate, setAnimate] = useState(false);
  const [widthMultiplier, setWidthMultiplier] = useState(6);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setWindowSize({ width, height });
      setWidthMultiplier(width < 1200 ? 1 : 6);
    };

    window.addEventListener('resize', handleResize);

    // Initial call to set the size correctly
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (signalReceived) {
      setAnimate(true);
      console.log('animating');
    }
  }, [signalReceived]);

  const containerStyle = {
    width: windowSize.width * 0.3,
    height: windowSize.height * 0.7,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flexWrap: 'wrap',
    gap: '20px', // Abstand zwischen den Bildern
    alignSelf: 'flex-start',
  };

  const imgStyle = {
    width: (windowSize.width * 0.7) / widthMultiplier,
    height: (windowSize.height * 0.7) / 6,
  };

  const handleClick = (url)=>{
    window.open(url)
  }

  return (
    <div className="svg-container" style={containerStyle}>
    <img src={instaSVG} alt="InstaSVG" style={imgStyle} className={`svg-img ${animate ? "fade-in-bottom" : "hidden"}`} onClick={() => handleClick('https://www.instagram.com/t_recs__/')} />
    <img src={spotifySVG} alt="SpotifySVG" style={imgStyle} className={`svg-img ${animate ? "fade-in-top" : "hidden"}`} onClick={() => handleClick('https://open.spotify.com/artist/7rvpKt6ORsYHKxHyKi9Zsr?si=ArI01U8HQXqWNW7qyR3LVA')} />
    <img src={appleSVG} alt="AppleSVG" style={imgStyle} className={`svg-img ${animate ? "fade-in-left" : "hidden"}`} onClick={() => handleClick('https://music.apple.com/de/artist/t-recs/1586213004')} />
    <img src={youtubeSVG} alt="YouTubeSVG" style={imgStyle} className={`svg-img ${animate ? "fade-in-right" : "hidden"}`} onClick={() => handleClick('https://www.youtube.com/channel/UCnN6Q6LlkON3H6IWcegnB8g')} />
    <img src={twitchSVG} alt="TwitchSVG" style={imgStyle} className={`svg-img ${animate ? "fade-in-left" : "hidden"}`} onClick={() => handleClick('https://www.twitch.tv/t_to_the_recs')} />
    <img src={deezerSVG} alt="DeezerSVG" style={imgStyle} className={`svg-img ${animate ? "fade-in-right" : "hidden"}`} onClick={() => handleClick('https://www.deezer.com/de/artist/145756642')} />
    <img src={tiktokSVG} alt="TikTokSVG" style={imgStyle} className={`svg-img ${animate ? "fade-in-right" : "hidden"}`} onClick={() => handleClick('https://www.tiktok.com/@t_recs__')} />
    </div>
  );
  
};

export default Svg_Container;
